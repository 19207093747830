/* eslint-disable react/display-name */
import { memo, useState } from 'react'
import { FileImage, FileVideo, Sparkles } from 'lucide-react'
import { MediaJobStatus, Page } from '@/api/pages/types'
import { Skeleton } from '@/components/shadcn/Skeleton'
import PlaceholderV2 from '@/assets/placeholder-img.png'
import { getStoryAssetImageUrl } from '@/api/pages/requests'
import { cn } from '@/utils/shadcn/cn'
import { getFileMediaType } from '@/utils/fileExtensions'
import VideoFailure from './VideoFailure'
import VideoProgress from './VideoProgress'
import { Tooltip, TooltipContent, TooltipTrigger } from '../shadcn/Tooltip'

const StoryPageImage = memo(({ page }: { page: Page }) => {
  const [isLoading, setIsLoading] = useState(true)
  const mediaType = getFileMediaType(page.imageS3Key)
  const mediaSource = mediaType === 'video' ? page.coverS3Key : page.imageS3Key

  const mediaUrl = mediaSource ? getStoryAssetImageUrl(mediaSource) : PlaceholderV2

  if (page.mediaJobStatus === MediaJobStatus.PROGRESSING) return <VideoProgress />

  if (page.mediaJobStatus === MediaJobStatus.ERROR) return <VideoFailure />

  return (
    <div className="relative">
      <img
        src={mediaUrl}
        className={cn('h-80 w-44 max-w-44 rounded-md object-cover', isLoading && 'hidden')}
        alt="story"
        onLoad={() => setIsLoading(false)}
      />
      <div className="absolute left-2 top-2 z-10 flex space-x-2">
        <div className="flex h-6 w-6 items-center justify-center rounded bg-white/70 text-slate-700">
          {mediaType === 'video' && (
            <Tooltip>
              <TooltipTrigger>
                <FileVideo size={14} />
              </TooltipTrigger>
              <TooltipContent>Video</TooltipContent>
            </Tooltip>
          )}
          {mediaType === 'image' && (
            <Tooltip>
              <TooltipTrigger>
                <FileImage size={14} />
              </TooltipTrigger>
              <TooltipContent>Obrázek</TooltipContent>
            </Tooltip>
          )}
        </div>
      </div>
      {page.generated && (
        <div className="absolute right-2 top-2 z-10 flex h-6 w-6 items-center justify-center rounded border border-white/50 bg-slate-700 text-white">
          <Tooltip>
            <TooltipTrigger>
              <Sparkles className="size-4" />
            </TooltipTrigger>
            <TooltipContent>Automaticky vygenerovaný příběh</TooltipContent>
          </Tooltip>
        </div>
      )}
      <Skeleton className={cn('h-80 w-44 max-w-48 rounded-md object-contain', !isLoading && 'hidden')} />
    </div>
  )
})

export default StoryPageImage

import { Sparkles } from 'lucide-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dialog, DialogContent, DialogTrigger } from '@/components/shadcn/Dialog'
import { GenerateCollection } from '@/forms/GenerateCollection'
import { getEditorialFromSlug } from '@/utils/slugEditorialConversion'
import { Editorial } from '@/api/stories/types'
import { buttonVariants } from '../shadcn/Button'

const FORBIDDEN_EDITORIALS = [Editorial.EVROPA_2]

const GenerateCollectionDialog = () => {
  const [open, setOpen] = useState(false)

  const { slug } = useParams()

  const editorial = getEditorialFromSlug(slug)

  if (!editorial) return null

  if (FORBIDDEN_EDITORIALS.includes(editorial)) return null

  return (
    <Dialog open={open} onOpenChange={value => setOpen(value)}>
      <DialogTrigger
        onClick={() => setOpen(true)}
        className={buttonVariants({ variant: 'secondary', className: 'flex gap-2.5 px-4 py-2 shadow-none' })}
      >
        <Sparkles className="size-4" />
        Vygenerovat kolekci
      </DialogTrigger>
      <DialogContent>
        <GenerateCollection onClose={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  )
}

export default GenerateCollectionDialog

import { Eye, Share, Trash2 } from 'lucide-react'
import moment from 'moment'
import { MouseEvent } from 'react'
import usePublishStory from '@/api/stories/mutations/usePublishStory'
import { type Story } from '@/api/stories/types'
import { Button, buttonVariants } from '@/components/shadcn/Button'
import config from '@/config'
import { useConfirmActionDialog } from '@/stores/useConfirmDialog'
import { CONFIRM_TEXT } from '@/constants/ConfirmText'
import Loader from '@/components/Loader'
import useDeleteStory from '@/api/stories/mutations/useDeleteStory'
import { cn } from '@/utils/shadcn/cn'

interface StoryCardActionsProps {
  story: Story
}

const StoryCardActions = ({ story }: StoryCardActionsProps) => {
  const { mutate: mutatePublish, isPending } = usePublishStory()
  const { openDialog } = useConfirmActionDialog()

  const { mutate: mutateDelete } = useDeleteStory()

  const deleteStory = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    mutateDelete({ storyId: story.id })
  }

  const publishStory = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    openDialog({
      title: CONFIRM_TEXT.PUBLISH_STORY.title,
      description: CONFIRM_TEXT.PUBLISH_STORY.description,
      onCancel: () => {},
      onConfirm: () => {
        mutatePublish({ id: story.id, published: moment().toDate(), ttl: moment().add(7, 'days').toDate() })
      },
    })
  }

  return (
    <div className="flex flex-col items-center justify-center gap-2 transition-all duration-200 ">
      <a
        className={cn(buttonVariants({ variant: story.pages.length === 0 ? 'disabled' : 'ghost' }), 'w-full justify-start')}
        href={`${config.api.storyBaseUrl}/story/preview/${story.id}`}
        onClick={e => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
      >
        <Eye className="mr-2 size-5" />
        Náhled
      </a>
      <Button
        variant={story.pages.length === 0 ? 'disabled' : 'ghost'}
        className="z-50 flex min-w-full items-center justify-start font-bold"
        onClick={publishStory}
      >
        {!isPending ? (
          <>
            <Share className="mr-2 size-5" />
            Publikovat
          </>
        ) : (
          <Loader />
        )}
      </Button>
      <Button variant="ghost" className="flex w-full justify-start font-bold text-red-600 hover:bg-gray-300" onClick={deleteStory}>
        <Trash2 className="mr-2 size-5" />
        Smazat
      </Button>
    </div>
  )
}

export default StoryCardActions

export enum CognitoGroup {
  ADMIN = 'admin',
  ISPORT = 'isport',
  AUTO = 'auto',
  BLESK = 'blesk',
  EVROPA2 = 'evropa2',
  AHA = 'aha',
  MATERIDOUSKA = 'materidouska',
  POGGERS = 'poggers',
}

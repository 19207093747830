import mime from 'mime'

export const shortenFileNameWithExtension = (fileName: string, maxLength: number) => {
  if (fileName.length <= maxLength) return fileName
  const extension = fileName.split('.').pop()
  if (!extension) return fileName.slice(0, maxLength - 1)
  const fileNameWithoutExtension = fileName.slice(0, fileName.length - extension.length - 1)
  return `${fileNameWithoutExtension.slice(0, maxLength - extension.length - 1)}...${extension}`
}

export const getFileMediaType = (fileName: string): 'image' | 'video' | null => {
  const extension = mime.getType(fileName)

  if (!extension) return null
  const type = extension.split('/')[0]
  if (type === 'image' || type === 'video') {
    return type
  }
  throw new Error(`Unsupported media type: ${type}`)
}

import { z } from 'zod'
import { MediaJobStatus } from '@/api/pages/types'

export const addPageSchema = z.object({
  id: z.string(),
  idStory: z.string(),
  label: z.string().trim().min(1, 'Název je povinný'),
  text: z.string().trim().max(65, 'Maximální délka textu je 65 znaků'),
  emoji: z.string().trim(),
  link: z.string().url('Neplatná URL. Ujistěte se, že obsahuje protokol (http:// nebo https://).').or(z.literal('')),
  imageS3Key: z.string().trim().min(1, 'Fotka je povinná'),
  coverS3Key: z.string().nullable(),
  originalS3Key: z.string().nullable(),
  duration: z.number().nullable(),
  published: z.date().nullable().or(z.string()),
  archive: z.boolean().optional(),
  previewTill: z.date().nullable().optional(),
  updateImage: z.boolean().optional(),
  updateCoverImage: z.boolean().optional(),
  cropBBox: z
    .object({
      left: z.number(),
      top: z.number(),
      width: z.number(),
      height: z.number(),
    })
    .optional()
    .nullable(),
  order: z.number().optional(),
  tempCropBBox: z.any().optional(),
  generated: z.boolean().default(false),
  mediaJobId: z.string().nullable(),
  mediaJobStatus: z.nativeEnum(MediaJobStatus).nullable(),
})

export type PagePreview = z.infer<typeof addPageSchema>

// Validation of a media files is handled in DropZoneUploader component
export const imageSchema = z.any().refine(files => files.length > 0, 'Fotka je povinná')

import { useNavigate } from 'react-router-dom'
import { Button } from '@/components/shadcn/Button'

const Page404 = () => {
  const navigate = useNavigate()

  return (
    <div className="h-screen bg-accent">
      <div className="mx-auto flex h-full w-full max-w-lg flex-col items-start justify-center space-y-8">
        <div className="flex flex-col space-y-2">
          <h1 className="scroll-m-20 text-2xl font-semibold">Stránka nenalezena</h1>
          <p>Kampak se ztratila? 😕</p>
        </div>
        <div className="flex w-full items-center space-x-2">
          <Button className="w-full" onClick={() => navigate(-1)}>
            Zpět
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Page404

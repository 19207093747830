import { Editorial } from '@/api/stories/types'

const SLUG_EDITORIAL = {
  blesk: Editorial.BLESK,
  evropa_2: Editorial.EVROPA_2,
  isport: Editorial.ISPORT,
  aha: Editorial.AHA,
  auto: Editorial.AUTO,
  materidouska: Editorial.MATERIDOUSKA,
  poggers: Editorial.POGGERS,
}

const getEditorialFromSlug = (slug?: string): Editorial | undefined => {
  if (!slug) return undefined

  return SLUG_EDITORIAL[slug]
}

const getSlugFromEditorial = (editorial?: Editorial | null): string | undefined => {
  if (!editorial) return undefined

  return Object.keys(SLUG_EDITORIAL).find(slug => SLUG_EDITORIAL[slug] === editorial)
}

export { getEditorialFromSlug, getSlugFromEditorial }

import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { ResponseError } from '@/api/errors'
import { deleteStory } from '@/api/stories/requests'
import { DeleteStoryParams, DeleteStoryResponse } from '@/api/stories/types'
import Keys from '@/api/keys'
import { TOAST_TEXT } from '@/constants/ConfirmText'

const useDeleteStory = (
  config?: UseMutationOptions<DeleteStoryResponse, ResponseError, DeleteStoryParams>
): UseMutationResult<DeleteStoryResponse, ResponseError, DeleteStoryParams> => {
  const client = useQueryClient()

  return useMutation({
    mutationKey: [Keys.DELETE_STORY],
    mutationFn: (payload: DeleteStoryParams) => deleteStory(payload),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: [Keys.STORIES] })
      toast.success(TOAST_TEXT.DELETE_STORY.success)
    },
    onError: e => {
      toast.error(TOAST_TEXT.DELETE_STORY.error)
      toast.message(e.response?.data?.message)
    },
    ...config,
  })
}

export default useDeleteStory

import { useRef, useState } from 'react'
import { Calendar as CalendarIcon, XIcon } from 'lucide-react'
import moment from 'moment'
import { Calendar } from '@/components/shadcn/Calendar'
import { Button } from '@/components/shadcn/Button'
import useFilter from '@/stores/useFilter'
import { useOnClickOutside } from '@/hooks/useOnOutsideClick'
import { Input } from '@/components/shadcn/Input'

const FilterCalendar = () => {
  const [showCalendar, setShowCalendar] = useState(false)
  const innerBorderRef = useRef(null)

  useOnClickOutside([innerBorderRef], () => {
    setShowCalendar(false)
  })

  const { date, setDate } = useFilter()

  return (
    <div className="relative hidden flex-col md:flex" ref={innerBorderRef}>
      <div className="flex h-full items-center rounded-md">
        {date ? (
          <div className="relative flex h-full items-center">
            <Input
              value={moment(date).format('DD/MM/YY')}
              readOnly
              className="h-full border-white bg-transparent text-sm text-white"
              onClick={() => setShowCalendar(true)}
            />
            <Button
              variant="ghost"
              aria-label="Resetovat text"
              className=" absolute right-4 h-auto p-1.5 text-white"
              onClick={() => setDate(null)}
            >
              <XIcon className="size-4" />
            </Button>
          </div>
        ) : (
          <Button
            variant="ghost"
            size="icon"
            className="h-full text-brand-foreground"
            onClick={() => {
              setShowCalendar(!showCalendar)
            }}
          >
            <CalendarIcon className="size-4" />
          </Button>
        )}
      </div>
      {showCalendar && (
        <Calendar
          selected={date ? new Date(date) : undefined}
          onDayClick={(day: Date) => {
            setDate(day)
            setShowCalendar(false)
          }}
          className="absolute right-0 top-12 rounded-md border bg-white shadow-md"
        />
      )}
    </div>
  )
}

export default FilterCalendar

import { useMutation, UseMutationOptions, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { publishStory } from '@/api/stories/requests'
import { PublishStoryBody, PublishStoryResponse } from '@/api/stories/types'
import { ResponseError } from '@/api/errors'
import Keys from '@/api/keys'
import { TOAST_TEXT } from '@/constants/ConfirmText'

const usePublishStory = (
  config?: UseMutationOptions<PublishStoryResponse, ResponseError, PublishStoryBody>
): UseMutationResult<PublishStoryResponse, ResponseError, PublishStoryBody> => {
  const client = useQueryClient()

  return useMutation({
    mutationKey: [Keys.PUBLISH_STORY],
    mutationFn: (payload: PublishStoryBody) => publishStory(payload),
    onSuccess: () => {
      client.invalidateQueries()
      toast.success(TOAST_TEXT.PUBLISH_STORY.success)
    },
    onError: e => {
      toast.error(TOAST_TEXT.PUBLISH_STORY.error)
      toast.message(e.response?.data?.message)
    },
    ...config,
  })
}

export default usePublishStory

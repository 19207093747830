import { LinkIcon } from 'lucide-react'
import { useState } from 'react'
import CopyExistingStoryForm from './StoryUrlForm'
import PageList from './PageList'
import { buttonVariants } from '../shadcn/Button'
import { Dialog, DialogContent, DialogTrigger } from '../shadcn/Dialog'
import { MultiStepper } from '../Stepper/MultiStepper'
import { MultiStepperProvider } from '../Stepper/useMultiStepper'

const CopyExistingStoryDialog = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dialog open={isOpen} onOpenChange={isOpen => setIsOpen(isOpen)}>
      <DialogTrigger onClick={() => setIsOpen(!isOpen)} className={buttonVariants({ variant: 'outline', className: 'flex gap-2' })}>
        <LinkIcon size={20} />
        Vložit existující
      </DialogTrigger>
      <DialogContent>
        <MultiStepperProvider totalSteps={2} initialStep={0} initState={undefined}>
          <MultiStepper
            steps={[<CopyExistingStoryForm key="story-form" setIsOpen={setIsOpen} />, <PageList key="page-list" setIsOpen={setIsOpen} />]}
          />
        </MultiStepperProvider>
      </DialogContent>
    </Dialog>
  )
}

export default CopyExistingStoryDialog

import { generatePath, useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { Editorial, type GetStoriesResponse } from '@/api/stories/types'
import Routes from '@/routes/routes'
import Loader from '@/components/Loader'
import EditorialLogo from '@/components/EditorialLogo'
import FilterItem from './FilterItem'

interface FiterListProps {
  data: GetStoriesResponse | undefined
  isPending: boolean
  expand: boolean
  setExpand: (expand: boolean) => void
  setFilterValue: (filter: string) => void
}

const FilterList = ({ data, expand = false, isPending, setFilterValue, setExpand }: FiterListProps) => {
  const navigate = useNavigate()

  const handleSelection = useCallback(
    (storyId: string, editorial: Editorial) => {
      const path = generatePath(Routes.STORY_DETAIL, { storyId, slug: editorial.toLowerCase() })

      navigate(path)
      setExpand(false)
      setFilterValue('')
    },
    [navigate, setExpand, setFilterValue]
  )
  if (!expand) return null

  if (isPending)
    return (
      <div className="flex items-center space-x-2 p-4 text-sm text-slate-700">
        <Loader className="size-4" />
        <span>Načítání</span>
      </div>
    )

  if (data?.length === 0) return <div className="p-4 text-sm">Žádné výsledky</div>

  return (
    <div className="flex flex-col  p-2">
      {data?.map(story => (
        <FilterItem
          key={story.id}
          onClick={() => {
            handleSelection(story.id, story.editorial)
          }}
        >
          <EditorialLogo type="circle" className="w-8 shrink-0 text-base font-bold" editorial={story.editorial} />
          <span>{story.title}</span>
        </FilterItem>
      ))}
    </div>
  )
}

export default FilterList

import { UseFormReturn } from 'react-hook-form'
import { z } from 'zod'
import { useEffect, useRef, useState } from 'react'
import { Pencil, PencilOff } from 'lucide-react'
import { DialogTitle } from '@/components/shadcn/Dialog'
import { Button } from '@/components/shadcn/Button'
import { addPageSchema } from './schema'

type Props = {
  form: UseFormReturn<z.infer<typeof addPageSchema>>
}

const MAX_LENGTH = 60

const EditableTitle = ({ form }: Props) => {
  const [isEditable, setIsEditable] = useState(false)

  const value = form.watch('label')

  const editableRef = useRef<HTMLDivElement>(null)

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    form.setValue('label', e.currentTarget.innerText)
    setIsEditable(false)
  }

  const handleOnClick = () => {
    setIsEditable(!isEditable)
  }

  const handleBeforeInput = (e: React.FormEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const nativeEvent = e.nativeEvent as InputEvent

    if (target.textContent && nativeEvent.data) {
      const currentLength = target.textContent.length || 0
      const incomingLength = nativeEvent.data.length || 0

      if (currentLength + incomingLength > MAX_LENGTH) {
        e.preventDefault()
      }
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    const pastedText = e.clipboardData.getData('text')

    e.preventDefault()

    const currentLength = target.textContent?.length || 0
    const incomingLength = pastedText.length

    if (currentLength + incomingLength <= MAX_LENGTH) {
      const selection = window.getSelection()
      if (selection?.rangeCount) {
        const range = selection.getRangeAt(0)
        range.deleteContents()
        range.insertNode(document.createTextNode(pastedText))

        range.collapse(false)
      }
    }
  }

  useEffect(() => {
    const range = document.createRange()
    const selection = window.getSelection()

    const element = editableRef.current

    if (isEditable && element && selection) {
      element.click()

      range.setStart(element, element.childNodes.length)
      range.collapse(true)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }, [isEditable])

  return (
    <>
      <input type="hidden" {...form.register('label')} />
      <div className="-m-2 flex max-w-2xl items-baseline space-x-4">
        <DialogTitle
          ref={editableRef}
          contentEditable={isEditable}
          role="textbox"
          spellCheck={false}
          suppressContentEditableWarning
          onBlur={handleBlur}
          onBeforeInput={handleBeforeInput}
          onPaste={handlePaste}
          className="break-all rounded-sm p-2 content-editable:focus:border-none content-editable:focus:outline-offset-2 content-editable:active:border-none content-editable:active:outline-offset-2"
        >
          {value}
        </DialogTitle>
        <Button size="icon" aria-label="Upravit název" onClick={handleOnClick} variant="ghost" className="shrink-0">
          {isEditable ? <PencilOff className="size-4" /> : <Pencil className="size-4" />}
        </Button>
      </div>
    </>
  )
}

export default EditableTitle

import { zodResolver } from '@hookform/resolvers/zod'
import { KeyboardEvent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { type z } from 'zod'
import { toast } from 'sonner'

import moment from 'moment'
import { Form } from '@/components/shadcn/Form'
import { useModal } from '@/stores/useModal'
import { type Page } from '@/api/pages/types'
import useSaveStoryPage from '@/api/pages/mutatations/useSaveStoryPage'
import useAddStoryPage from '@/api/pages/mutatations/useAddStoryPage'
import { TOAST_TEXT } from '@/constants/ConfirmText'
import Keys from '@/api/keys'
import useIsLoading from '@/hooks/useIsLoading'
import { useMultiStepper } from '@/components/Stepper/useMultiStepper'
import { getFileMediaType } from '@/utils/fileExtensions'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/shadcn/Dialog'
import DialogScrollArea from '@/components/DialogScrollArea'
import { Button } from '@/components/shadcn/Button'
import { addPageSchema } from './schema'

import ActionsPageForm from './ActionsPageForm'
import PreviewController from './PreviewController'
import { useMediaChange } from './handlers/useOnImageChange'
import ImageCropper from './ImageCropper'
import EditableTitle from './EditableTitle'
import ItemInput from '../inputs/ItemInput'
import EmojiInput from '../inputs/EmojiPicker'
import TextAreaInput from '../inputs/TextAreaInput'
import DropZoneUploader from '../inputs/DropZoneUploader'

interface PageFormProps {
  initialValues: Page | z.infer<typeof addPageSchema>
  isNew: boolean
}

const PageForm = ({ initialValues, isNew }: PageFormProps) => {
  const { isLoading, setIsLoadingManually } = useIsLoading([Keys.SAVE_PAGE, Keys.CREATE_PAGE])
  const { progress, onMediaChange, media, isImageCropperOpen, setIsImageCropperOpen, onCoverChange } = useMediaChange()

  const client = useQueryClient()
  const { mutate: addPage } = useAddStoryPage()
  const { mutate: savePage } = useSaveStoryPage()

  const { onClose } = useModal()
  const { state: multiStepState } = useMultiStepper()

  const form = useForm<z.infer<typeof addPageSchema>>({
    resolver: zodResolver(addPageSchema),
    mode: 'all',
    defaultValues: initialValues,
  })

  const reset = async () => {
    await client.invalidateQueries()
    onClose()
  }

  const createNewPage = (values: Page) => {
    return {
      ...values,
      ttl: values.published ? moment().add(7, 'days').toDate() : null,
    }
  }

  const handleSuccess = (page: Page) => {
    toast.success(TOAST_TEXT.EDIT_PAGE.success)
    if (page.published) toast.success(TOAST_TEXT.PUBLISH_PAGE.success)
    reset()
  }

  const handleError = (error: Error) => {
    toast.error(TOAST_TEXT.EDIT_PAGE.error)
    toast.error(error.message)
  }

  const checkForCover = (page: Page) => {
    if (getFileMediaType(page.imageS3Key) === 'image') return false
    if (!page.coverS3Key) {
      form.setError('coverS3Key', { type: 'required', message: 'Náhled videa je povinný!' })
      return true
    }
    return false
  }

  const onFinish = async (values: z.infer<typeof addPageSchema>): Promise<void> => {
    if (checkForCover(values)) return
    setIsLoadingManually(true)
    const basePage: Page = {
      ...values,
      previewTill: null,
      idTemplate: multiStepState.idTemplate,
      generated: false,
      mediaJobId: null,
      mediaJobStatus: null,
    }
    const page = isNew ? createNewPage(basePage) : basePage
    checkForCover(page)

    const action = isNew ? addPage : savePage

    action(page, {
      onSuccess: () => handleSuccess(page),
      onError: handleError,
    })
  }

  const setCropBBoxValues = () => {
    const cropBBox = form.getValues('tempCropBBox')
    form.setValue('cropBBox', cropBBox)
    setIsImageCropperOpen(false)
  }

  // reset form when initialValues change
  useEffect(() => {
    form.reset(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const handleKeyUp = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = 'inherit'
    e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`
  }

  return (
    <Form {...form}>
      <DialogHeader>
        <EditableTitle form={form} />
      </DialogHeader>
      <DialogScrollArea>
        <form onSubmit={form.handleSubmit(onFinish)} className="h-full">
          <div className="flex gap-16">
            <div className="flex flex-1 flex-col gap-4">
              <TextAreaInput
                control={form.control}
                className="min-h-0 resize-none overflow-hidden"
                label="Hlavní text"
                rows={1}
                name="text"
                onKeyUp={handleKeyUp}
                placeholder="Zobrazovaný text může obsahovat maximálně 65 znaků"
              />
              <EmojiInput label="Emoji" name="emoji" />
              <ItemInput control={form.control} label="Odkaz na článek" name="link" placeholder="www.odkaz.cz" />
              <DropZoneUploader progress={progress} onMediaChange={onMediaChange} label="Vložit média" />
            </div>
            <PreviewController isNew={isNew} mediaFile={media} onMediaChange={onMediaChange} onCoverChange={onCoverChange} />
            <Dialog open={isImageCropperOpen} onOpenChange={open => setIsImageCropperOpen(open)}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Oříznutí fotky</DialogTitle>
                </DialogHeader>
                <DialogScrollArea>
                  <ImageCropper image={media} />
                </DialogScrollArea>
                <DialogFooter>
                  <Button onClick={setCropBBoxValues}>Oříznout</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </form>
      </DialogScrollArea>
      <DialogFooter>
        <ActionsPageForm isLoading={isLoading} action={onFinish} isNew={isNew} />
      </DialogFooter>
    </Form>
  )
}

export default PageForm

import { Editorial } from '@/api/stories/types'
import { CognitoGroup } from '@/types/users'

const GROUP_EDITORIAL: Record<Exclude<CognitoGroup, CognitoGroup.ADMIN>, Editorial> = {
  [CognitoGroup.BLESK]: Editorial.BLESK,
  [CognitoGroup.EVROPA2]: Editorial.EVROPA_2,
  [CognitoGroup.ISPORT]: Editorial.ISPORT,
  [CognitoGroup.AHA]: Editorial.AHA,
  [CognitoGroup.AUTO]: Editorial.AUTO,
  [CognitoGroup.MATERIDOUSKA]: Editorial.MATERIDOUSKA,
  [CognitoGroup.POGGERS]: Editorial.POGGERS,
}

const getEditorialFromGroup = (groups: CognitoGroup[]): Editorial | null => {
  const foundGroup = groups.find(group => GROUP_EDITORIAL[group])

  if (!foundGroup) return null

  return GROUP_EDITORIAL[foundGroup]
}

export default getEditorialFromGroup
